//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-168:_988,_4988,_7904,_2063,_1624,_9184,_4108,_2608;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-168')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-168', "_988,_4988,_7904,_2063,_1624,_9184,_4108,_2608");
        }
      }catch (ex) {
        console.error(ex);
      }